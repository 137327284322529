<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div v-if="this.$store.getters.discriminator==='administrator'" class="col-3 ml-0 p-0">
      <multiselect v-model="oficeFilter" @input="filterData" :options="offices" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Offices" label="office_name" track-by="id" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.map(item=>{return item.office_name}).join(", ") }}</span></template>
      </multiselect>
    </div>
    <el-table 
      :data="this.tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.email.toLowerCase().includes(search.toLowerCase()) || data.phone.toLowerCase().includes(search.toLowerCase()) || data.office.toLowerCase().includes(search.toLowerCase()))" 
      :default-sort="{ prop: 'name', order: 'ascending' }"
      class="w-100">
      <el-table-column prop="firstname" sortable label="First Name"></el-table-column>
      <el-table-column prop="lastname" sortable label="Last Name"></el-table-column>
      <el-table-column prop="email" sortable label="Email" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="phone" sortable label="Phone"></el-table-column>
      <el-table-column prop="office.office_name" sortable label="Office"></el-table-column>
      <el-table-column label="Operations">
        <template slot="header" slot-scope="scope">
          <el-input v-model="search" placeholder="Search..." class="p-0" :key="scope.row" />
        </template>
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="mini" icon="el-icon-edit" @click="update(scope.row,scope.$index)"></el-button>
            <el-button size="mini" icon="el-icon-delete" @click="remove(scope.row,scope.$index)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="fixed-bottom new-register" type="success" @click="add">Add New Representative</el-button>

    <el-drawer title="Representative" :visible.sync="component.drawer" direction="rtl" size="500px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-representative v-on:refresh="load($event)" :data="component.data" :key="component.render" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import representative from "@/services/api/representative";
import ComponentRepresentative from "@/components/admin/Representative";
import Multiselect from 'vue-multiselect';

export default {
  components: {
    ComponentRepresentative,
    Multiselect
  },
  data() {
    return {
      data:[],
      tableData: [],
      oficeFilter:[],
      search:"",
      offices: [], 
      component: {
        data: null,
        drawer: false,
        render: 0
      },
      index: null
    };
  },
  mounted() {
    office.get().then(response => {
          this.offices = response;
        });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        representative.get().then(response => {
          this.data = Object.assign([], response);
          this.tableData = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            representative.getRepresentativesOffice(idOffice).then(response => {
              this.data = Object.assign([], response);
              this.tableData = response;
            });
        }); 
        break;
    }    
  },
  methods: {
    load(event) {
      this.component.drawer = false;
      let idOffices = this.oficeFilter.map(office=>{
          return office.id;
        });
      if (event.update) {
        if(idOffices.length>0 && idOffices.indexOf(event.data.office.id)===-1){
          this.tableData.splice(this.index, 1);
        }else{
          this.tableData.splice(this.index, 1, event.data);
        }
        let indexUpdate=null;
        this.data.forEach(function(element, index, array){
          if(element.id==event.data.id){
            indexUpdate=index;
          }
        });

        this.data.splice(indexUpdate, 1, event.data);
      } else {
        if(idOffices.length>0){
          if(idOffices.indexOf(event.data.office.id)!=-1){
            this.tableData.unshift(event.data);
          }
        }else{
          this.tableData.unshift(event.data);
        }
        this.data.unshift(event.data);
      }
      this.tableData.sort(this.compare);
    },
    filterData(){
      let idOffices = this.oficeFilter.map(office=>{
        return office.id;
      });
      if(idOffices.length>0){
        this.tableData=this.data.filter(item=>idOffices.indexOf(item.office.id)!=-1);
      }else{
        this.tableData = Object.assign([], this.data);
      }
      this.tableData.sort(this.compare);
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    remove(row, index) {
      this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#67C23A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            representative
            .delete(row)
            .then(response => {
              this.tableData.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    }
  }
};
</script>
